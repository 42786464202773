<div class="content">
  <h1>Kompakkt Developer Consortium</h1>

  <p>
  Kompakkt is an open source suite of annotation tools currently in 
  development by a consortium of research institutions in Germany. 
  The goal of the consortium is to optimise development through improved 
  communication and coordination, so that new features are developed faster, 
  avoiding redundancy and prioritising long-term sustainability and reusability. 
  If you’re interested in joining the consortium, please contact: 
  <a href="mailto:info@kompakkt.de">info&#64;kompakkt.de</a>
  </p>
  <h2>
  Members:
  </h2>
  <img
    class="consortium-logo"
    src="assets/images/csm_IDH_Logo_593d3538d3.png"
    alt="Department of Digital Humanities Logo"
  />
  <p>
    <a href="http://dh.uni-koeln.de">Department for Digital Humanities</a><br />
    University of Cologne<br />
    Albertus-Magnus-Platz<br />
    50931 Köln<br /><br />
    <a href="mailto:info@kompakkt.de">info&#64;kompakkt.de</a>
  </p>
  <br>
  <img
    class="consortium-logo"
    src="/assets/images/TIB_Logo_EN_RGB_100px.png"
    alt="TIB Logo"
  />
  <p>
    <a href="https://www.tib.eu/de/forschung-entwicklung/forschungsgruppen-und-labs/open-science">
      Open Science Lab
    </a>
    <br />
    TIB – Leibniz Information Centre for Science and Technology<br />
    Lange Laube 28<br />
    30159 Hannover<br /><br />
  </p>

  <p>For individual contacts, consult the <a routerLink="/contact">Contact</a> Page.</p>"
</div>
